import React from "react";
import { ErrorBoundary } from '@sentry/react';
import { asyncWithLDProvider } from 'launchdarkly-react-client-sdk';
import ReactDOM from "react-dom/client";
import { ChakraProvider } from "@chakra-ui/react";
import App from "./App";
import Error from "./Error";
import { RudderEvent, rudderanalytics } from './utils/rudderstack';
import config from "./config";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

(async () => {
  const LDProvider = await asyncWithLDProvider({
    clientSideID: config.launchDarklyClientId,
    context: {
      kind: 'user',
      anonymous: true,
    },
    options: {
      streaming: false,
    },
    reactOptions: {
      useCamelCaseFlagKeys: false,
    },
  });
  const onError = (
    error: unknown,
    eventId: string,
    componentStack: string
  ) => {
    rudderanalytics.track(RudderEvent.Error, {
      error_message: "Rudder error",
      pathname: window.location.pathname,
    });
  };
  root.render(
    <React.StrictMode>
      <ErrorBoundary fallback={Error} onError={onError}>
        <LDProvider>
          <ChakraProvider>
            <App />
          </ChakraProvider>
        </LDProvider>
      </ErrorBoundary>
    </React.StrictMode>
  );
})();