interface Config {
    launchDarklyClientId: string;
    rudderstack: {
      writeKey: string;
      dataPlaneUrl: string;
    };
  }
  type Environments = 'test' | 'development' | 'staging' | 'production';
  
  type Configs = {
    [env in Environments]: Config;
  };
  
  // this helps test locally on mobile devices
  // replace with your ip address; Mac: open terminal and type ipconfig getifaddr en0
  // You'll need to also add this the baseUrl(http://${devBase}:3001) to the Application URIs/Allowed Origins in the auth0 dashboard
  
  const configs: Configs = {
    test: {
      launchDarklyClientId: '66577b1c0755fa0fdfc31a81',
      rudderstack: {
        writeKey: '',
        dataPlaneUrl: '',
      },
    },
    development: {
      launchDarklyClientId: '66577b1c0755fa0fdfc31a81',
      rudderstack: {
        writeKey: '',
        dataPlaneUrl: '',
      },
    },
    staging: {
      launchDarklyClientId: '66577b1c0755fa0fdfc31a81',
      rudderstack: {
        writeKey: '2fYyHIk4fDdc2LKTqhRoWxy2MNQ',
        dataPlaneUrl: 'https://leaseenddiodkq.dataplane.rudderstack.com',
      },
    },
    production: {
      launchDarklyClientId: '66577ad87a34591067ca5421',
      rudderstack: {
        writeKey: '2fYyHIk4fDdc2LKTqhRoWxy2MNQ',
        dataPlaneUrl: 'https://leaseenddiodkq.dataplane.rudderstack.com',
      },
    },
  };
  
  export const getEnv = () => process.env.REACT_APP_STAGE || process.env.NODE_ENV;
  export const isLocal = getEnv() === 'development';
  export const isStaging = getEnv() === 'staging';
  export const isProd = getEnv() === 'production';
  
  export default configs[getEnv() as Environments];
  