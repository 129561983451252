// eslint-disable-next-line no-restricted-imports
import { LDClient, useLDClient } from 'launchdarkly-react-client-sdk';
import { useEffect, useRef, useState } from 'react';

import { isLocal, isStaging } from '../config';
import { FlagOverrides, LDFlags } from '../constants/LDFlags'

const REFLECT_KEY = 'reflectFlags';

const reflectFlags = JSON.parse(localStorage.getItem(REFLECT_KEY) as string);
// This attempts to import ../flagOverrides.ts but uses a default {} if it doesn't exist
let flagOverrides: { default: FlagOverrides } = { default: {} };
try {
  // eslint-disable-next-line global-require, import/no-unresolved, import/extensions
  flagOverrides = require('../flagOverrides');
} catch (ex) {
  // do nothing: use default empty flags
}

const overrideLogic = (flag: LDFlags, flagValue: unknown) => {
  if (isLocal || isStaging) {
    if (reflectFlags?.[flag] != null) {
      return reflectFlags[flag];
    }
    if (flagOverrides.default[flag] != null) {
      return flagOverrides.default[flag];
    }
  }
  return flagValue;
};

export const evaluateFlag = (ldClient: LDClient | undefined, flag: LDFlags) => {
  const flagValue = ldClient?.variation(flag as unknown as string);
  return overrideLogic(flag, flagValue);
};

// a way to override flags for development until launchdarkly supports this https://github.com/launchdarkly/react-client-sdk/issues/53
// view flagOverrides.sample.ts in the root directory for more information
const useFlag = (flag: LDFlags) => {
  const ldClient = useLDClient();
  const prevFlag = useRef(flag);
  const [flagValue, setFlagValue] = useState(() => evaluateFlag(ldClient, flag));

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const updateFlag = (newFlagValue: unknown) => {
    setFlagValue(overrideLogic(flag, newFlagValue));
  };

  useEffect(() => {
    if (flag !== prevFlag.current) {
      prevFlag.current = flag;
      setFlagValue(evaluateFlag(ldClient, flag));
    }

    ldClient?.on(`change:${flag}`, updateFlag);
    return () => {
      ldClient?.off(`change:${flag}`, updateFlag);
    };
  }, [flag, ldClient, updateFlag]);

  return flagValue;
};

export default useFlag;
