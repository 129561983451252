import useFlag from "../hooks/useFlag"
import { LDFlags } from "../constants/LDFlags"
import { useEffect, useState } from "react";
import { RudderEvent, rudderanalytics } from "../utils/rudderstack";
import { isLocal } from "../config";
import VariationDefault from "./variations/VariationDefault";
import VariationSmoke from "./variations/VariationSmoke";
import VariationDog from "./variations/VariationDog";
import VariationHeartHands from "./variations/VariationHeartHands";
import VariationBlackCar from "./variations/VariationBlackCar";
import Variation5Star from "./variations/Variation5Star";

const Lab = () => {
    const [urlParms, setUrlParams] = useState<string>("")
    const variation = useFlag(LDFlags.HOME_PAGE)
    const title = "EndMyAutoLease.com";
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const up = new URLSearchParams(window.location.search)
    
    const handleCta = () => {
      rudderanalytics.track(RudderEvent.CTA, {
        event: 'EndMyAutoLease-COM::CTA',
      });
      window.open("https://www.leaseend.com" + urlParms);
    };
    
    useEffect(() => {
      let p:string = "?anon_id=" + rudderanalytics.getAnonymousId()
      if(up) {
        up.forEach((value, key) => {
          p+= "&" + key + "=" + value
          setUrlParams(urlParms)
        })
      }
      setUrlParams(p)
      if(!isLocal) {
        rudderanalytics.page(title);
      }
    }, [up, urlParms]);
    
    switch(variation) {
      case 'dog':
        return <VariationDog handleCta={handleCta} />
      case 'heart-hands':
        return <VariationHeartHands handleCta={handleCta} />
      case 'smoke':
        return <VariationSmoke handleCta={handleCta} />
      case 'black-car':
        return <VariationBlackCar handleCta={handleCta} />
      case '5-star':
        return <Variation5Star handleCta={handleCta} />
      default:
        return <VariationDefault handleCta={handleCta} />
    }
}

export default Lab