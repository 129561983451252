import { useEffect, useState } from "react";
import { rudderanalytics } from "./utils/rudderstack";
import { isLocal } from "./config";
import Lab from "./components/Lab";

const App = () => {
  const title = "EndMyAutoLease.com";
  const [urlParms, setUrlParams] = useState<string>("")
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const up = new URLSearchParams(window.location.search)
  useEffect(() => {
    let p:string = "?anon_id=" + rudderanalytics.getAnonymousId()
    if(up) {
      up.forEach((value, key) => {
        p+= "&" + key + "=" + value
        setUrlParams(urlParms)
      })
    }
    setUrlParams(p)
    if(!isLocal) {
      rudderanalytics.page(title);
    }
  }, [title, up, urlParms]);
  return (
    <Lab />
  );
};

export default App;
