import { Box, Flex, Text } from '@chakra-ui/react';
import * as Sentry from '@sentry/react';
import React from 'react';

const Error: React.ComponentProps<typeof Sentry.ErrorBoundary>['fallback'] = () => {
  return (
    <Flex alignItems="center" justifyContent="center" minH="100vh" minW="100vw" p={10}>
      <Box maxW="600px" textAlign="center">
        <Text>
          We encountered an unexpected error. Please refresh the page using the button below and try
          again. If the issue persists, give us a call at{' '}
        </Text>
      </Box>
    </Flex>
  );
};

export default Error;
