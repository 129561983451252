import { Flex, VStack, Stack, Button, Text, Box } from "@chakra-ui/react";
import { BackgroundDefault } from "../../assets/Images";

const VariationDefault = (props: {
  handleCta: Function
}) => {
  return (
    <>
    <Box
      bgImage={BackgroundDefault}
      bgSize="cover"
      bgPosition="center"
      bgRepeat="no-repeat"
      width="100vw"
      height="100vh"
      overflow="hidden"
      position="relative"
    >
      <Flex
        align="center"
        justify={{ base: "center", md: "right" }}
        height="100%"
      >
        <VStack spacing="space-between">
          <Box
            w={{ base: "300px", md: "425px", lg: "550px" }}
            h={{ base: "200px", md: "250px", lg: "280px" }}
            mx={{ base: "10vw", lg: "15vw" }}
            bg="#127dc2"
            borderRadius="10px"
          >
            <Stack>
              <Text
                p="15px"
                fontWeight="bold"
                fontSize={{ base: "18px", md: "25px", lg: "32px" }}
                textColor="white"
              >
                The Fastest, Easiest, Most Cost-Effective way for you to buy your
                leased vehicle.
              </Text>
              <Text
                textAlign="left"
                px="15px"
                mb={{ base: "unset", md: "10px" }}
                fontSize="12px"
                textColor="white"
              >
                Click here to learn more:
              </Text>
              <Button
                bgColor="#F4C53E"
                borderRadius="0"
                color="#1B1A1B"
                fontSize={{ base: "16px", md: "19px" }}
                _hover={{
                  bg: "#F7D267",
                  boxShadow: "md",
                }}
                minW={{ base: "150px", md: "200px", lg: "250px" }}
                mx="auto"
                onClick={()=>props.handleCta()} 
                className="le-lab-lp-cta"
              >
                Learn More
              </Button>
            </Stack>
          </Box>
        </VStack>
      </Flex>
    </Box>
    </>
  );
};

export default VariationDefault;
