import * as rudderanalytics from 'rudder-sdk-js';

import config from '../config';

if(config.rudderstack.writeKey) {
    rudderanalytics.load(config.rudderstack.writeKey, config.rudderstack.dataPlaneUrl);
}

enum RudderEvent {
  Error = 'Error',
  LabTest = 'LabTest',
  LabTestSuccess = 'LabTestSuccess',
  CTA = 'CTA'
}

export { rudderanalytics, RudderEvent };
